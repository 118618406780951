<template>
  <div style="display:none;" v-html="form"></div>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      form: "",
    };
  },
  mounted() {
    if (localStorage.getItem("data")) {
      this.form = localStorage.getItem("data");
      Object.keys(localStorage).forEach((item) =>
        item.indexOf("data") !== -1 ? localStorage.removeItem(item) : ""
      );
    }
    var that = this;
    setTimeout(function () {
      that.payclick();
    }, 500);
  },
  methods: {
    payclick() {
      document.forms["alipaysubmit"].submit();
    },
  },
};
</script>
